import React, { useEffect, useMemo, useState } from 'react';
import { useTable, useFilters } from 'react-table';
import '../../Pages/inventoryNewBags/InventoryNewBags.css';

export const InventoryNewBags = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [filterInput, setFilterInput] = useState('');

  const fetchData = () => {
    const token = localStorage.getItem('token');
    console.log('Retrieved Token:', token);

    if (!token) {
      setError('Token not found. Please log in again.');
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `${token}`,
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    };

    fetch('https://app.lvintage.com/admin/newBag/stock', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        console.log('API response:', result);
        if (result && result.result && Array.isArray(result.result)) {
          const formattedData = result.result.map((item) => ({
            sNo: item.id,
            category: item.categoryName,
            size: item.size,
            stockAvailability: item.stockAvailability,
          }));
          setData(formattedData);
        } else {
          console.error('Unexpected response format:', result);
          throw new Error('Unexpected response format');
        }
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        setError(error.message);
      });
  };

  useEffect(() => {
    fetchData(); // Fetch stock data on component mount
  }, []);

  const columns = useMemo(
    () => [
      { Header: 'S.No', accessor: 'sNo' },
      { Header: 'Category', accessor: 'category' },
      { Header: 'Size', accessor: 'size' },
      { Header: 'Stock Availability', accessor: 'stockAvailability' },
      {
        Header: 'Add Stock',
        accessor: 'addStock',
        Cell: ({ row }) => (
          <button
            className="add-stock-btn"
            onClick={() => handleAddStockClick(row.original.sNo)} // Passing ID to handleAddStockClick
          >
            Add Stock +
          </button>
        ),
      },
    ],
    []
  );

  const handleAddStockClick = (id) => {
    const token = localStorage.getItem('token');

    if (!token) {
      console.error('Token not found. Please log in again.');
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `${token}`,
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    };

    fetch(`https://app.lvintage.com/admin/addStock/${id}`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        console.log('Stock successfully increased:', result);
        fetchData(); // Refresh the stock list after adding stock
      })
      .catch((error) => {
        console.error('Fetch error:', error);
      });
  };

  const tableInstance = useTable({ columns, data }, useFilters);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = tableInstance;

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter('category', value);
    setFilterInput(value);
  };

  return (
    <div>
      <div className="filter-container">
        <label htmlFor="categoryFilter">Filter by Category: </label>
        <select
          id="categoryFilter"
          value={filterInput}
          onChange={handleFilterChange}
        >
          <option value="">All</option>
          <option value="Speedy">Speedy</option>
          <option value="Neverfull">Neverfull</option>
          <option value="Keepall">Keepall</option>
        </select>
      </div>
      {error && <p>Error: {error}</p>}
      <table {...getTableProps()} className="modern-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default InventoryNewBags;
