import React, { useEffect, useMemo, useState } from 'react';
import { useTable } from 'react-table';
import '../../Pages/inventorySecondLife/InventorySecondLife.css';
import '../../Pages/orderNewBags/OrderNewBags.css';
import { useNavigate } from 'react-router-dom';

export const Support = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log('Retrieved Token:', token);

    if (!token) {
      setError('Token not found. Please log in again.');
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `${token}`, // Ensure "Bearer" prefix is included
        'Content-Type': 'application/json',
      },
    };

    fetch('https://app.lvintage.com/admin/userSupports', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        console.log('API response:', result);

        if (result && result.result && Array.isArray(result.result)) {
          // Format data according to the response structure
          const formattedData = result.result.map((item, index) => ({
            sNo: index + 1,
            name: item.userName,
            email: item.email,
            ticketNo: item.ticketNumber,
            status: item.status,
            description: item.issueDescription,
            attachments: item.attachments.length > 0 ? 'Yes' : 'No', // Example for attachments
          }));

          setData(formattedData);
        } else {
          console.error('Unexpected response format:', result);
          throw new Error('Unexpected response format');
        }
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        setError(error.message);
      });
  }, []);

  const columns = useMemo(
    () => [
      { Header: 'S.No', accessor: 'sNo' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'Ticket No', accessor: 'ticketNo' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Description', accessor: 'description' },
      { Header: 'Attachments', accessor: 'attachments' },
    ],
    []
  );

  const tableInstance = useTable({ columns, data });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <div>
      {error && <p className="error-message">{error}</p>}
      <table {...getTableProps()} className="modern-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Support;
