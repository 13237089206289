import React, { useState } from 'react';
import { useLocation, Routes, Route, Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import '../../Pages/Sidebar/Sidebar.css';
import Dashboard from '../../Pages/dashboard/Dashboard.js';
import InventoryNewBags from '../inventoryNewBags/InventoryNewBags.js';
import InventorySecondLife from '../inventorySecondLife/InventorySecondLife.js';
import OrderNewBags from '../orderNewBags/OrderNewBags.js';
import OrderSecondLife from '../orderSecondLife/OrderSecondLife.js';
import OrderNewViewDetail from '../orderNewViewdetail/OrderNewViewDetail.js'; // Ensure this import
import SecoundLifeSell from '../secoundLifeSell/SecoundLifeSell.js';
import RepairService from '../repairService/RepairService.js';
import Support from '../support/Support.js';
import OrderSecoundLifeView from '../orderSecondLifeView/OrderSecondLifeView.js';
import RepairViewDetail from '../repairViewDetail/RepairViewDetal.js';
import SecoundLifeSellView from '../secoundLifeSellView/SecoundLifeSellView.js';
import UserManagement from '../userManagement/UserManagement.js';

import Logos from '../../../Components/Images/VintageLogo.png';
import Group6802 from '../../../Components/Images/Group6802.png';
import Group6803 from '../../../Components/Images/Group6803.png';
import Group6804 from '../../../Components/Images/Group6804.png';
import Group6805 from '../../../Components/Images/Group6805.png';
import Group6806 from '../../../Components/Images/Group6806.png';
import Group6807 from '../../../Components/Images/Group6807.png';
import homeicon from '../../../Components/Images/Group6808.png';
import Group6809 from '../../../Components/Images/Group6809.png';
import usermanimg from '../../Images/User Management.png'
import ChangeUserPwd from '../changeUserPwd/ChangeUserPwd.js';

const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isOrderSubMenuOpen, setIsOrderSubMenuOpen] = useState(false);
    const [isInventorySubMenuOpen, setIsInventorySubMenuOpen] = useState(false);

    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    const toggleOrderSubMenu = () => {
        setIsOrderSubMenuOpen(!isOrderSubMenuOpen);
    };

    const toggleInventorySubMenu = () => {
        setIsInventorySubMenuOpen(!isInventorySubMenuOpen);
    };

    const handleLogout = () => {
        navigate("/logout");  // Navigate to the logout route
      };

    return (
        <div className="container-fluid sidbr">
            <div className="row">
                <div className="col-md-3 sidbrs">
                    <div className="sidebar">
                        <div className="logo">
                            <img src={Logos} alt="Logo" />
                        </div>
                        <nav className="menu">
                            <ul>
                                <li className={`menu-item ${isActive('/dashboard')}`}>
                                    <Link to="/dashboard">
                                        <img src={homeicon} alt="" />
                                        <span>Dashboard</span>
                                    </Link>
                                </li>
                                <li className={`menu-item ${isActive('/')}`}>
                                    <Link to="#" onClick={toggleInventorySubMenu}>
                                        <img src={Group6809} alt="" />
                                        <span>Inventory Management <i className="fas fa-caret-down"></i></span>
                                    </Link>
                                </li>
                                <div className='mysubmneu'>
                                    {isInventorySubMenuOpen && (
                                        <ul className="submenu">
                                            <li className={`menu-item ${isActive('/inventoryNewBags')}`}>
                                                <Link to="/inventoryNewBags">
                                                    <img src={Group6807} alt="" />New Bags 
                                                </Link>
                                            </li>
                                            <li className={`menu-item ${isActive('/inventorySecondLife')}`}>
                                                <Link to="/inventorySecondLife">
                                                    <img src={Group6806} alt="" /> Second Life Bags
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                </div>
                                <li className={`menu-item ${isActive('/')}`}>
                                    <Link to="#" onClick={toggleOrderSubMenu}>
                                        <img src={Group6805} alt="" />
                                        <span>Order Management <i className="fas fa-caret-down"></i></span>
                                    </Link>
                                </li>
                                <div className='mysubmneu'>
                                    {isOrderSubMenuOpen && (
                                        <ul className="submenu">
                                            <li className={`menu-item ${isActive('/orderNewBags')}`}>
                                                <Link to="/orderNewBags">
                                                    <img src={Group6807} alt="" />New Bags 
                                                </Link>
                                            </li>
                                            <li className={`menu-item ${isActive('/orderSecondLife')}`}>
                                                <Link to="/orderSecondLife">
                                                    <img src={Group6806} alt="" /> Second Life Bags
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                </div>
                                <li className={`menu-item ${isActive('/usermanagement')}`}>
                                    <Link to="/usermanagement">
                                        <img src={usermanimg} alt="" />
                                        <span>User Management</span>
                                    </Link>
                                </li>
                                <li className={`menu-item ${isActive('/secoundLifeSell')}`}>
                                    <Link to="/secoundLifeSell">
                                        <img src={Group6806} alt="" />
                                        <span>Second life Sell</span>
                                    </Link>
                                </li>
                               
                                <li className={`menu-item ${isActive('/repairService')}`}>
                                    <Link to="/repairService">
                                        <img src={Group6804} alt="" />
                                        <span>Repair service</span>
                                    </Link>
                                </li>
                                <li className={`menu-item ${isActive('/support')}`}>
                                    <Link to="/support">
                                        <img src={Group6803} alt="" />
                                        <span>Support</span>
                                    </Link>
                                </li>
                                <li className="menu-item ncs">
                                    <img src={Group6802} alt="" />
                                    <button className='logutbtn' onClick={handleLogout}>Logout</button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-md allpages allpagess">
                    <div className='pages'>
                        <Routes>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/inventoryNewBags" element={<InventoryNewBags />} />
                            <Route path="/inventorySecondLife" element={<InventorySecondLife />} />
                            <Route path="/orderNewBags" element={<OrderNewBags />} />
                            <Route path="/orderSecondLife" element={<OrderSecondLife />} />
                            <Route path="/orderNewViewDetail/:orderItemId" element={<OrderNewViewDetail />} /> {/* Updated route */}
                            <Route path="/secoundLifeSell" element={<SecoundLifeSell />} />
                            <Route path="/repairService" element={<RepairService />} />
                            <Route path="/support" element={<Support />} />
                            <Route path="/orderSecoundLifeView/:orderItemId" element={<OrderSecoundLifeView />} /> {/* Updated route */}
                            <Route path="/repairViewDetail/:orderItemId" element={<RepairViewDetail />} /> {/* Updated route */}
                            <Route path="/secoundLifeSellView/:orderItemId" element={<SecoundLifeSellView />} /> {/* Updated route */}
                            <Route path="/usermanagement/" element={<UserManagement/>} />
                            <Route path="/changeUserPwd/:sNo" element={<ChangeUserPwd />} /> {/* Updated route */}
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
