import React, { useMemo, useEffect, useState } from 'react';
import { useTable, useFilters } from 'react-table';
import '../../Pages/inventorySecondLife/InventorySecondLife.css';

export const InventorySecondLife = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [filterInput, setFilterInput] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log('Retrieved Token:', token);

    if (!token) {
      setError('Token not found. Please log in again.');
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `${token}`, // Adding 'Bearer' before the token
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    };

    fetch('https://app.lvintage.com/admin/oldBag/stock', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        console.log('API response:', result);
        if (result && result.result && Array.isArray(result.result)) {
          // Format the data for the table
          const formattedData = result.result.map((item) => ({
            sNo: item.id,
            category: item.categoryName,
            size: item.size,
            stockAvailability: item.stockAvailibility,
            sellBy: item.sellBy 
          }));
          setData(formattedData);
        } else {
          console.error('Unexpected response format:', result);
          throw new Error('Unexpected response format');
        }
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        setError(error.message);
      });
  }, []);

  const columns = useMemo(
    () => [
      { Header: 'S.No', accessor: 'sNo' },
      { Header: 'Category', accessor: 'category' },
      { Header: 'Size', accessor: 'size' },
      { Header: 'Stock Availability', accessor: 'stockAvailability' },
      { Header: 'Sell By', accessor: 'sellBy' },
    ],
    []
  );

  const [categoryFilterInput, setCategoryFilterInput] = useState('');
  const [sellByFilterInput, setSellByFilterInput] = useState('');

  const tableInstance = useTable({ columns, data }, useFilters);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = tableInstance;

  const handleCategoryFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter('category', value);
    setCategoryFilterInput(value);
  };

  const handleSellByFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter('sellBy', value);
    setSellByFilterInput(value);
  };

  return (
    <div>
      {error && <p className="error-message">{error}</p>}
      <div className="filter-container filter-containers">
        <label htmlFor="categoryFilter">Filter by Category: </label>
        <select
          id="categoryFilter"
          value={categoryFilterInput}
          onChange={handleCategoryFilterChange}
        >
          <option value="">All</option>
          <option value="Speedy">Speedy</option>
          <option value="Neverfull">Neverfull</option>
          <option value="Keepall">Keepall</option>
        </select>

        <label htmlFor="sellByFilter" className='filtrsell'>Filter by Sell By: </label>
        <select
          id="sellByFilter"
          value={sellByFilterInput}
          onChange={handleSellByFilterChange}
        >
          <option value="">All</option>
          <option value="Customer">Customer</option>
          <option value="Manually">Manually</option>
        </select>
      </div>

      <table {...getTableProps()} className="modern-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default InventorySecondLife;
