import React, { useState, useEffect } from 'react';
import { useParams , useNavigate } from 'react-router-dom';
import '../repairViewDetail/RepairViewDetal.css'; // Import the CSS file

const RepairViewDetail = () => {
  const { orderItemId } = useParams(); // Extract 'orderItemId' from the URL
  const [detailData, setDetailData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    console.log('URL ID:', orderItemId); // Log the ID to verify

    if (!orderItemId) {
      setError('Invalid ID');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      setError('Token not found. Please log in again.');
      return;
    }

    console.log('Token:', token); // Log the token to verify

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `${token}`,
        'Content-Type': 'application/json',
      },
    };

    fetch(`https://app.lvintage.com/admin/repairRequests`, requestOptions)
      .then(response => {
        console.log('Response Status:', response.status); // Log response status
        if (response.status === 401) {
          throw new Error('Unauthorized: Check your token');
        }
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(result => {
        console.log('API Response:', result); // Log the response

        if (result && Array.isArray(result.result)) {
          const foundDetail = result.result.find(item => item.id === parseInt(orderItemId));
          if (foundDetail) {
            setDetailData(foundDetail);
          } else {
            setError('No data found for the given ID');
          }
        } else {
          console.error('Unexpected response format:', result);
          setError('Unexpected response format');
        }
      })
      .catch(error => {
        console.error('Fetch error:', error);
        setError(error.message);
      });
  }, [orderItemId]);

  if (error) return <div className="error-message">{error}</div>;
  if (!detailData) return <div className="loading">Loading...</div>;

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className='container rpirvie'>
      <i class="fa fa-arrow-left" onClick={handleBack} style={{marginLeft: '-26px',marginTop: '-23px',width: '34px',}}></i>
      <div className='row'>
        <div className='col-md-6'>
          <div className="repair-view-detail">
            <h1>Repair Request Details</h1>
            <div className="details">
              <p><strong>ID:</strong> {detailData.id}</p>
              <p><strong>Email ID:</strong> {detailData.emailId}</p>
              <p><strong>Mobile Number:</strong> {detailData.mobileNumber}</p>
              <p><strong>Category:</strong> {detailData.category}</p>
              <p><strong>Status:</strong> {detailData.status}</p>
              <p><strong>Estimate Cost:</strong> {detailData.estimateCost}</p>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          {/* Render images if they exist */}
          {detailData.images && detailData.images.length > 0 && (
            <div className="image-gallery">
              {detailData.images.map((imageUrl, index) => (
                <img key={index} src={imageUrl} alt={`Repair Image ${index + 1}`} className="image" />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>

  );
};

export default RepairViewDetail;
