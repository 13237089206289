import React, { useEffect, useState } from 'react';
import '../../Pages/orderNewViewdetail/OrderNewViewDetail.css';
import { useParams , useNavigate } from 'react-router-dom';

export const SecoundLifeSellView = () => {
  const { orderItemId } = useParams(); // Extract orderItemId from the URL
  const [orderDetails, setOrderDetails] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize navigate


  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      setError('Token not found. Please log in again.');
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
    };

    fetch('https://app.lvintage.com/admin/secondLife/sell', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        if (result && result.result && Array.isArray(result.result.orderResponses)) {
          const matchingOrder = result.result.orderResponses.find((order) =>
            order.orderItemResponses.some((item) => item.orderItemId === parseInt(orderItemId))
          );

          if (matchingOrder) {
            const matchingItem = matchingOrder.orderItemResponses.find(
              (item) => item.orderItemId === parseInt(orderItemId)
            );

            setOrderDetails({
              orderId: matchingOrder.orderId,
              orderItemId: matchingItem.orderItemId,
              category: matchingItem.categoryName || 'Unknown Category',
              size: matchingItem.size || 'Unknown Size',
              customisationType: matchingItem.customisationType || 'N/A',
              deliveryStatus: matchingOrder.status || 'N/A',
              deliveryAddress:
                matchingOrder.addressResponse?.completeAddress || 'Unknown Address',
              cost: matchingItem.price || 'N/A',
              imagePath: matchingItem.itemImages || [], // Use itemImages array from API
            });

            
          } else {
            throw new Error('Order item not found');
          }
        } else {
          throw new Error('Unexpected response format');
        }
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        setError(error.message);
      });
  }, [orderItemId]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!orderDetails) {
    return <div>Loading...</div>;
  }

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="container-fluid orderview">
      <div className="row">
      <div className="col-md-1 preview"> 
      </div>
        <div className="col-md-10">
          <div className="order-details-container">
          <i class="fa fa-arrow-left" onClick={handleBack}></i>
            <h2 className="header-order-id">Order ID: {orderDetails.orderId}</h2>

            <div className="order-detail">
              <span className="label">Order Item ID:</span>
              <span className="value">{orderDetails.orderItemId}</span>
            </div>
            <div className="order-detail">
              <span className="label">Bag Category:</span>
              <span className="value">{orderDetails.category}</span>
            </div>
            <div className="order-detail">
              <span className="label">Size:</span>
              <span className="value">{orderDetails.size}</span>
            </div>

            <div className="order-detail">
              <span className="label">Customisation Type:</span>
              <span className="value">{orderDetails.customisationType}</span>
            </div>
            <div className="order-detail">
              <span className="label">Delivery Status:</span>
              <span className="value">{orderDetails.deliveryStatus}</span>
            </div>
            <div className="order-detail">
              <span className="label">Delivery Address:</span>
              <span className="value">{orderDetails.deliveryAddress}</span>
            </div>
            <div className="order-detail">
              <span className="label">Cost:</span>
              <span className="value">${orderDetails.cost}</span>
            </div>
          </div>
        </div>
        <div className="col-md-1 preview"> 
        </div>
      </div>
    </div>
  );
};

export default SecoundLifeSellView;
