import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./Components/Pages/Login/Login.js";
import Sidebar from "./Components/Pages/Sidebar/Sidebar.js";
import Logout from "./Components/Pages/logout/Logout";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state

  // Check localStorage for token on initial render to persist login
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true); // If token exists, user is authenticated
    }
    setLoading(false); // Set loading to false after token check
  }, []);

  // Render loading spinner or empty page while checking authentication status
  if (loading) {
    return <div></div>; // You can replace this with a loading spinner or a more sophisticated UI
  }

  return (
    <Routes>
      <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
      
      {/* Protected route for the sidebar/dashboard */}
      <Route 
        path="/*" 
        element={isAuthenticated ? <Sidebar /> : <Navigate to="/login" />}
      />
      
      {/* Logout route */}
      <Route 
        path="/logout" 
        element={<Logout setIsAuthenticated={setIsAuthenticated} />} 
      />
    </Routes>
  );
}

export default App;
