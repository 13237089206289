import React, { useState } from 'react';
import '../dasChart/DasChart.css';

import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';

const data = [
  { name: 'Jan', Orders: 4000, Stock: 2400, Users: 2400, Repairs: 1200 },
  { name: 'Feb', Orders: 3000, Stock: 1398, Users: 2210, Repairs: 1100 },
  { name: 'Mar', Orders: 2000, Stock: 9800, Users: 2290, Repairs: 1400 },
  { name: 'Apr', Orders: 2780, Stock: 3908, Users: 2000, Repairs: 1500 },
  { name: 'May', Orders: 1890, Stock: 4800, Users: 2181, Repairs: 1600 },
  { name: 'Jun', Orders: 2390, Stock: 3800, Users: 2500, Repairs: 1300 },
  { name: 'Jul', Orders: 3490, Stock: 4300, Users: 2100, Repairs: 1700 },
];

const Chart = () => {
  const [activeBar, setActiveBar] = useState(''); // Empty string means all bars are visible

  const handleLegendClick = (dataKey) => {
    setActiveBar(dataKey === activeBar ? '' : dataKey);
  };

  return (
    <div className="chart-container">
      <div className="chart-title">Business Overview</div>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend onClick={(e) => handleLegendClick(e.dataKey)} />
          {(activeBar === '' || activeBar === 'Orders') && <Bar dataKey="Orders" fill="#8884d8" />}
          {(activeBar === '' || activeBar === 'Stock') && <Bar dataKey="Stock" fill="#82ca9d" />}
          {(activeBar === '' || activeBar === 'Users') && <Bar dataKey="Users" fill="#ffc658" />}
          {(activeBar === '' || activeBar === 'Repairs') && <Bar dataKey="Repairs" fill="#ff7300" />}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
  