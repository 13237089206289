import React, { useState } from 'react';
import '../../Pages/changeUserPwd/ChangeUserPwd.css';

const ChangeUserPwd = () => {
  // Change Password Form State
  const [emailPwdChange, setEmailPwdChange] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  // Create User Form State
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [createUserError, setCreateUserError] = useState(null);
  const [createUserSuccessMessage, setCreateUserSuccessMessage] = useState(null);

  // Handle Password Change Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('email', emailPwdChange);
    formData.append('password', password);

    const token = localStorage.getItem('token');

    if (!token) {
      setError('Token not found. Please log in again.');
      return;
    }

    try {
      const response = await fetch(`https://app.lvintage.com/admin/reset/user/password`, {
        method: 'POST',
        headers: {
          'Authorization': token,
        },
        body: formData,
      });

      if (response.ok) {
        setSuccessMessage('Password changed successfully!');
        setEmailPwdChange('');
        setPassword('');
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'An error occurred while changing the password.');
      }
    } catch (error) {
      setError('An unexpected error occurred.');
    }
  };

  // Handle Create User Form Submission
  const handleCreateUserSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('userEmail', userEmail); // Update key to 'email'
    formData.append('mobileNumber', mobileNumber);
    formData.append('userPassword', userPassword); // Update key to 'password'
    
    // Log FormData contents
    const logFormData = (formData) => {
      const data = {};
      formData.forEach((value, key) => {
        data[key] = value;
      });
      console.log(data);
    };
    
    logFormData(formData);
    
    const token = localStorage.getItem('token');
    
    if (!token) {
      setCreateUserError('Token not found. Please log in again.');
      return;
    }
    
    try {
      const response = await fetch('https://app.lvintage.com/admin/create/user', {
        method: 'POST',
        headers: {
          'Authorization': token,
          // Note: 'Content-Type' header should not be set for FormData
        },
        body: formData,
      });
      
      if (response.ok) {
        setCreateUserSuccessMessage('User created successfully!');
        setFirstName('');
        setLastName('');
        setUserEmail('');
        setMobileNumber('');
        setUserPassword('');
      } else {
        const errorData = await response.json();
        console.error('Error details:', errorData);
        setCreateUserError(errorData.message || 'An error occurred while creating the user.');
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      setCreateUserError('An unexpected error occurred.');
    }
  };

  
  // Password Visibility Toggle
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container usrpwdch">
      <div className='row'>
        {/* Change Password Section */}
        <div className='col-md-6'>
          <h1>Create New Password</h1>
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <label>Email</label>
              <input
                type="email"
                id="emailPwdChange"
                name="emailPwdChange"
                placeholder="Your Email ID"
                value={emailPwdChange}
                onChange={(e) => setEmailPwdChange(e.target.value)}
                required
              />
            </div>

            <div className="input-container">
              <label>New Password</label>
              <div className="password-input">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Your New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <i
                  className="eye-icon"
                  onClick={togglePasswordVisibility}
                  style={{ cursor: 'pointer' }}
                >
                  {showPassword ? "🙈" : "👁️"}
                </i>
              </div>
            </div>

            <button type="submit" className="changepwd-button">Change Password</button>

            {error && <p className="error-message">{error}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}
          </form>
        </div>

        {/* Create User Section */}
        <div className='col-md-6'>
          <h1>Create User</h1>
          <form onSubmit={handleCreateUserSubmit}>
            <div className="input-container">
              <label>First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>

            <div className="input-container">
              <label>Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>

            <div className="input-container">
              <label>Email</label>
              <input
                type="email"
                id="userEmail"
                name="userEmail"
                placeholder="User Email ID"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                required
              />
            </div>

            <div className="input-container">
              <label>Mobile Number</label>
              <input
                type="text"
                id="mobileNumber"
                name="mobileNumber"
                placeholder="Mobile Number"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                required
              />
            </div>

            <div className="input-container">
              <label>Password</label>
              <div className="password-input">
                <input
                  type={showPassword ? "text" : "password"}
                  id="userPassword"
                  name="userPassword"
                  placeholder="User Password"
                  value={userPassword}
                  onChange={(e) => setUserPassword(e.target.value)}
                  required
                />
                <i
                  className="eye-icon"
                  onClick={togglePasswordVisibility}
                  style={{ cursor: 'pointer' }}
                >
                  {showPassword ? "🙈" : "👁️"}
                </i>
              </div>
            </div>

            <button type="submit" className="changepwd-button">Create User</button>

            {createUserError && <p className="error-message">{createUserError}</p>}
            {createUserSuccessMessage && <p className="success-message">{createUserSuccessMessage}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangeUserPwd;
