import React, { useMemo, useState, useEffect } from 'react';
import { useTable } from 'react-table';
import '../../Pages/inventorySecondLife/InventorySecondLife.css';
import '../../Pages/orderNewBags/OrderNewBags.css';
import { useNavigate } from 'react-router-dom'; // Hook for navigation
import '../userManagement/UserManagement.css';

export const UserManagement = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        const token = localStorage.getItem('token');
        console.log('Retrieved Token:', token);

        if (!token) {
            setError('Token not found. Please log in again.');
            return;
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json',
            },
        };

        fetch('https://app.lvintage.com/admin/users', requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((result) => {
                console.log('API response:', result);

                if (result && result.result && Array.isArray(result.result)) {
                    // Format data according to the response structure
                    const formattedData = result.result.map((item, index) => ({
                        sNo: item.id, // Use the actual user ID for deletion
                        name: `${item.firstName} ${item.lastName}`,
                        email: item.email,
                        phoneno: item.mobileNumber,
                    }));

                    setData(formattedData);
                } else {
                    console.error('Unexpected response format:', result);
                    throw new Error('Unexpected response format');
                }
            })
            .catch((error) => {
                console.error('Fetch error:', error);
                setError(error.message);
            });
    }, []);

    // Function to handle deleting a user
    const handleDeleteUserClick = (userId) => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('Token not found. Please log in again.');
            return;
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json',
            },
        };

        fetch(`https://app.lvintage.com/admin/deleteUser/${userId}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Failed to delete user. Status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                // Remove the deleted user from the state
                setData(prevData => prevData.filter(user => user.sNo !== userId));
            })
            .catch((error) => {
                console.error('Delete error:', error);
                setError(error.message);
            });
    };

    // Function to handle "Change Pwd" button click
    const handleChangePwdClick = (userId) => {
        navigate(`/ChangeUserPwd/${userId}`); // Redirect to the ChangeUserPwd page
    };
    

    const handleUserCreateClick = (userId) => {
        navigate(`/ChangeUserPwd/${userId}`); // Redirect to the ChangeUserPwd page
    };

    const columns = useMemo(
        () => [
            { Header: 'S.No', accessor: 'sNo' },
            { Header: 'Name', accessor: 'name' },
            { Header: 'Email', accessor: 'email' },
            { Header: 'Phone No', accessor: 'phoneno' },
            {
                Header: 'Change Pwd',
                Cell: ({ row }) => (
                    <div>
                        <button className="btn-action" onClick={() => handleChangePwdClick(row.original.sNo)}>Change Pwd</button>
                    </div>
                ),
            },
            // {
            //     Header: 'Create User',
            //     Cell: ({ row }) => (
            //         <div>
            //             <button className="btn-action" onClick={() => handleChangePwdClick(row.original.sNo)} style={{background: 'black'}}>Create User</button>
            //         </div>
            //     ),
            // },
            {
                Header: 'Delete User',
                Cell: ({ row }) => (
                    <div>
                        <button className="btn-action btn-delete"
                            onClick={() => handleDeleteUserClick(row.original.sNo)}
                        >Delete</button>
                    </div>
                ),
            },
        ],
        []
    );

    const tableInstance = useTable({ columns, data });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    return (
        <div>
            <div className='cretusrbtn'>
                <button className="btn-action" onClick={() => handleUserCreateClick()} style={{ background: 'black' }}>Create New User</button>
            </div>
            {error && <div className="error">{error}</div>}
            <table {...getTableProps()} className="modern-table">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default UserManagement;
