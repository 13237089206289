import React, { useEffect, useMemo, useState } from 'react';
import { useTable, useFilters } from 'react-table';
import '../../Pages/inventorySecondLife/InventorySecondLife.css';
import '../../Pages/orderNewBags/OrderNewBags.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for programmatic navigation

export const OrderNewBags = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [categoryFilterInput, setCategoryFilterInput] = useState('');
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log('Retrieved Token:', token);

    if (!token) {
      setError('Token not found. Please log in again.');
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': ` ${token}`, // Ensure "Bearer " prefix is included
        'Content-Type': 'application/json',
      },
    };

    fetch('https://app.lvintage.com/admin/newBag/orders', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        console.log('API response:', result);

        if (result && result.result && Array.isArray(result.result.orderResponses)) {
          // Flatten the order item responses into a single array
          let serialNumber = 1;
          const formattedData = result.result.orderResponses.flatMap(order =>
            order.orderItemResponses.map((item) => ({
              sNo: serialNumber++,
              orderItemId: item.orderItemId,
              bagId: item.bagId,
              category: item.categoryName,
              size: item.size,
              price: item.price,
              quantity: item.quantity,
              orderDetail: "View Details",  // You can customize this
            }))
          );

          setData(formattedData);
        } else {
          console.error('Unexpected response format:', result);
          throw new Error('Unexpected response format');
        }
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        setError(error.message);
      });
  }, []);

  const columns = useMemo(
    () => [
      { Header: 'S.No', accessor: 'sNo' },
      { Header: 'Order Item ID', accessor: 'orderItemId' },
      { Header: 'Bag ID', accessor: 'bagId' },
      { Header: 'Category', accessor: 'category' },
      { Header: 'Size', accessor: 'size' },
      { Header: 'Price', accessor: 'price' },
      { Header: 'Quantity', accessor: 'quantity' },
      {
        Header: 'Order Detail',
        accessor: 'orderDetail',
        Cell: ({ row }) => (
          <button
            className="view-detail-button"
            onClick={() => navigate(`/orderNewViewDetail/${row.original.orderItemId}`)}
          >
            {row.original.orderDetail}
          </button>
        ),
      },
    ],
    [navigate]
  );

  const tableInstance = useTable({ columns, data }, useFilters);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = tableInstance;

  const handleCategoryFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter('category', value);
    setCategoryFilterInput(value);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div className="filter-container filter-containers">
        <label htmlFor="categoryFilter">Filter by Category: </label>
        <select
          id="categoryFilter"
          value={categoryFilterInput}
          onChange={handleCategoryFilterChange}
        >
          <option value="">All</option>
          <option value="Speedy">Speedy</option>
          <option value="Neverfull">Neverfull</option>
          <option value="Keepall">Keepall</option>
        </select>
      </div>

      <table {...getTableProps()} className="modern-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default OrderNewBags;
