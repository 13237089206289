import React, { useState , useEffect } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import Logos from '../../../Components/Images/VintageLogo.png';
import '../../Pages/Login/Login.css';

const Login = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // New state for password visibility
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    try {
      const response = await axios.post('https://app.lvintage.com/admin/signIn', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const result = response.data;
      console.log('Login Response:', result); // Log API response
      if (response.status === 200) {
        console.log('Saving Token:', result.result.token); // Log token
        localStorage.setItem('token', result.result.token);
        setIsAuthenticated(true);
        navigate('/dashboard');
      } else {
        alert('Login failed');
      }
    } catch (error) {
      console.error('Login Error:', error);
      alert('An error occurred');
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className='loginbg'>
      <div className="login-container">
        <div className="logo">
          <img src={Logos} alt="Logo" />
        </div>
        <h2>Welcome Back</h2>
        <p>Hello there, login to continue</p>

        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <label>Email</label>
            <input 
              type="email"
              id="email"
              name="email"
              placeholder="Your Email ID"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="input-container">
            <label>Password</label>
            <div className="password-input">
              <input
                type={showPassword ? "text" : "password"} // Toggle between text and password
                id="password"
                name="password"
                placeholder="Your Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <i
                className="eye-icon"
                onClick={togglePasswordVisibility} // Toggle visibility on click
                style={{ cursor: 'pointer' }}
              >
                {showPassword ? "🙈" : "👁️"} {/* Change icon based on visibility */}
              </i>
            </div>
          </div>

          <button type="submit" className="login-button">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
