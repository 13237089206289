import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useFilters } from 'react-table';
import { useNavigate } from 'react-router-dom';
import '../../Pages/inventorySecondLife/InventorySecondLife.css';
import '../../Pages/orderNewBags/OrderNewBags.css';

export const OrderSecondLife = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [categoryFilterInput, setCategoryFilterInput] = useState('');
  const [customizationTypeFilterInput, setCustomizationTypeFilterInput] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log('Retrieved Token:', token);

    if (!token) {
      setError('Token not found. Please log in again.');
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `${token}`, // Ensure "Bearer " prefix is included
        'Content-Type': 'application/json',
      },
    };

    fetch('https://app.lvintage.com/admin/oldBag/orders', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        console.log('API response:', result);

        if (result && result.result && Array.isArray(result.result.orderResponses)) {
          let serialNumber = 1;
          const formattedData = result.result.orderResponses.flatMap(order =>
            order.orderItemResponses.map((item) => ({
              sNo: serialNumber++,
              orderItemId: item.orderItemId,
              bagId: item.bagId,
              category: item.categoryName || 'N/A',
              customizationType: item.customizationType || 'N/A',
              size: item.size || 'N/A',
              price: item.price || 0,
              quantity: item.quantity || 0,
              orderDetail: "View Details",
            }))
          );

          setData(formattedData);
        } else {
          console.error('Unexpected response format:', result);
          throw new Error('Unexpected response format');
        }
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        setError(error.message);
      });
  }, []);

  const columns = useMemo(
    () => [
      { Header: 'S.No', accessor: 'sNo' },
      { Header: 'Category', accessor: 'category' },
      { Header: 'Size', accessor: 'size' },
      { Header: 'Customization Type', accessor: 'customizationType' },
      { Header: 'Price', accessor: 'price' },
      { Header: 'Quantity', accessor: 'quantity' },
      {
        Header: 'Order Detail',
        accessor: 'orderDetail',
        Cell: ({ row }) => (
          <button
            className="view-detail-button"
            onClick={() => navigate(`/orderSecoundLifeView/${row.original.orderItemId}`)}
          >
            {row.original.orderDetail}
          </button>
        ),
      },
    ],
    [navigate]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable({ columns, data }, useFilters);

  const handleCategoryFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter('category', value);
    setCategoryFilterInput(value);
  };

  const handleCustomizationTypeFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter('customizationType', value);
    setCustomizationTypeFilterInput(value);
  };

  return (
    <div>
      <div className="filter-container">
        <label htmlFor="categoryFilter">Filter by Category: </label>
        <select
          id="categoryFilter"
          value={categoryFilterInput}
          onChange={handleCategoryFilterChange}
        >
          <option value="">All</option>
          <option value="SPEEDY">Speedy</option>
          <option value="Neverfull">Neverfull</option>
          <option value="Keepall">Keepall</option>
        </select>

        <label htmlFor="customizationTypeFilter" className="filtrsell">Filter by Customization Type: </label>
        <select
          id="customizationTypeFilter"
          value={customizationTypeFilterInput}
          onChange={handleCustomizationTypeFilterChange}
        >
          <option value="">All</option>
          <option value="Normal Customization">Normal Customization</option>
          <option value="Art Customization">Art Customization</option>
          <option value="Simple Customization">Simple Customization</option>
        </select>
      </div>

      <table {...getTableProps()} className="modern-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {error && <p className="error">{error}</p>} {/* Display error if it exists */}
    </div>
  );
};

export default OrderSecondLife;
