import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Remove token from localStorage to expire the session
    localStorage.removeItem('token');
    
    // Set authentication status to false
    setIsAuthenticated(false);

    // Redirect to login page
    navigate('/login');
  }, [navigate, setIsAuthenticated]);

  return <div>Logging out...</div>;
};

export default Logout;
