import React, { useEffect, useState } from 'react';
import '../../Pages/orderNewViewdetail/OrderNewViewDetail.css';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import ThreeSixty from 'react-360-view';
import treesiximg from '../../Images/Animation - 1725956497022.gif'
import '../../Pages/orderSecondLifeView/OrderSecondLifeView.css'

export const OrderSecondLifeView = () => {
  const basePath = "https://lvintage.s3.us-east-1.amazonaws.com";
  const { orderItemId } = useParams(); // Extract orderItemId from the URL
  const [orderDetails, setOrderDetails] = useState(null);
  const [error, setError] = useState(null);
  const [pageName, setPageName] = useState("");
  const [imagePaths, setImagePaths] = useState([]); // State for storing image URLs 
  const [show360View, setShow360View] = useState(false); // New state for showing the 360 view
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      setError('Token not found. Please log in again.');
      return;
    }
    const extractPart = (url, basePath) => {
      const relativeUrl = url.replace(basePath, '');
      console.log('Relative URL:', relativeUrl); // Debug log
      const match = relativeUrl.match(/\/(\d*)?([a-zA-Z]+)\s*\d*\.png$/);
      console.log('Match:', match); // Debug log
      if (match) {
        if (match[1]) {
          return match[1] + match[2];
        } else {
          return match[2];
        }
      }
      return null;
    };

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': ` ${token}`,
        'Content-Type': 'application/json',
      },
    };

    fetch('https://app.lvintage.com/admin/oldBag/orders', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        if (result && result.result && Array.isArray(result.result.orderResponses)) {
          const matchingOrder = result.result.orderResponses.find(order =>
            order.orderItemResponses.some(item => item.orderItemId === parseInt(orderItemId))
          );

          if (matchingOrder) {
            const matchingItem = matchingOrder.orderItemResponses.find(item => item.orderItemId === parseInt(orderItemId));
            console.log(matchingItem.itemImages[0]);
            console.log(basePath);
            console.log(extractPart(matchingItem.itemImages[0]), basePath);
            setPageName(extractPart(matchingItem.itemImages[0]), basePath);
            setOrderDetails({
              orderId: matchingOrder.orderId,
              orderItemId: matchingItem.orderItemId,
              category: matchingItem.categoryName,
              size: matchingItem.size,
              customisationType: matchingItem.customisationType || 'N/A',
              deliveryStatus: matchingOrder.deliveryStatus || 'N/A',
              deliveryAddress: matchingOrder.deliveryAddress || 'N/A',
              cost: matchingItem.price,
              imagePath: matchingItem.itemImages || [], // Use itemImages array from API
            });
            setImagePaths(matchingItem.itemImages); // Set image paths
          } else {
            throw new Error('Order item not found');
          }
        } else {
          throw new Error('Unexpected response format');
        }
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        setError(error.message);
      });
  }, [orderItemId]);

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handlePreviewClick = () => {
    setShow360View(true); // Show the 360 view when button is clicked
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!orderDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container-fluid orderview">
      <i className="fa fa-arrow-left" onClick={handleBack}></i>
      <div className='row'>
        <div className="col-md-6">
          <div className="order-details-container">
            <h2 className="header-order-id">Order ID: {orderDetails.orderId}</h2>

            <div className="order-detail">
              <span className="label">Order Item ID:</span>
              <span className="value">{orderDetails.orderItemId}</span>
            </div>
            <div className="order-detail">
              <span className="label">Bag Category:</span>
              <span className="value">{orderDetails.category}</span>
            </div>
            <div className="order-detail">
              <span className="label">Size:</span>
              <span className="value">{orderDetails.size}</span>
            </div>

            <div className="order-detail">
              <span className="label">Customisation Type:</span>
              <span className="value">{orderDetails.customisationType}</span>
            </div>
            <div className="order-detail">
              <span className="label">Delivery Status:</span>
              <span className="value">{orderDetails.deliveryStatus}</span>
            </div>
            <div className="order-detail">
              <span className="label">Delivery Address:</span>
              <span className="value">{orderDetails.deliveryAddress}</span>
            </div>
            <div className="order-detail">
              <span className="label">Cost:</span>
              <span className="value">${orderDetails.cost}</span>
            </div>
          </div>
        </div>
        <div className="col-md-6 preview">
        {show360View && (
              <div className="Appx">
                <ThreeSixty
                  amount={23}
                  imagePath={basePath}
                  fileName={`${pageName} {index}.png`}
                  spinReverse
                />
              </div>
            )}
          <button type="button" className="preview button" onClick={handlePreviewClick}>
            <img src={treesiximg} alt="Preview button" /> Preview
          </button>
        </div>
      </div>
    </div>
  );
};
export default OrderSecondLifeView;
